import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAlert.figmaUrl.android} codeUrl={checklists.componentAlert.codeUrl.android} checklists={checklists.componentAlert.checklists} mdxType="ChecklistResourcesSection" />
    <p>{`The Alert component is utilized to present a temporary surface that provides a list of options. It displays a brief message or notification to the user within an existing activity.`}</p>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <br />
To implement Alert, you can use this tag `LgnAlertButton` in the xml file.
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:actionText="Call to action"
  app:alertType="INFO"
  app:description="Interactively monetize corporate alignment"
  app:title="Info title"
/>
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`In its use, alert has following variants :`}</p>
    <h3>{`Dismiss button without Action button`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:description="Interactively monetize corporate alignment"
  app:title="Info title"
/>
`}</code></pre>
    <h3>{`Without Action button and Dismiss button`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:description="Interactively monetize corporate alignment"
  app:isDismissible="false"
  app:title="Info title"
/>
`}</code></pre>
    <h3>{`Action button with Dismiss button`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:actionText="Call to action"
  app:description="Interactively monetize corporate alignment"
  app:title="Info title"
/>
`}</code></pre>
    <h3>{`Action button without Dismiss button`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:actionText="Call to action"
  app:description="Interactively monetize corporate alignment"
  app:isDismissible="false"
  app:title="Info title"
/>
`}</code></pre>
    <div className="divi" />
    <h2>{`Themes`}</h2>
    <p>{`There are 4 themes which are available :`}</p>
    <h3>{`Info`}</h3>
    <p>{`The following is properties value to implement Alert `}<inlineCode parentName="p">{`info`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:actionText="Call to action"
  app:alertType="INFO"
  app:description="Interactively monetize corporate alignment"
  app:title="Info title"
/>
`}</code></pre>
    <h3>{`Success`}</h3>
    <p>{`The following is properties value to implement Alert `}<inlineCode parentName="p">{`Success`}</inlineCode>{` .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:actionText="Call to action"
  app:alertType="SUCCESS"
  app:description="Interactively monetize corporate alignment"
  app:title="Info title"
/>
`}</code></pre>
    <h3>{`Warning`}</h3>
    <p>{`The following is properties value to implement Alert `}<inlineCode parentName="p">{`Warning`}</inlineCode>{` .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:actionText="Call to action"
  app:alertType="WARNING"
  app:description="Interactively monetize corporate alignment"
  app:title="Info title"
/>
`}</code></pre>
    <h3>{`Error`}</h3>
    <p>{`The following is properties value to implement Alert `}<inlineCode parentName="p">{`Error`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.alert.LgnAlert
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginHorizontal="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_20dp"
  app:actionText="Call to action"
  app:alertType="ERROR"
  app:description="Interactively monetize corporate alignment"
  app:title="Info title"
/>
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert Title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add title on alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert Description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add description on alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert Action Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:actionText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.actionText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add action text on alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert Dissmis Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isDismissible`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.isDismissible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add dismiss button visibility on alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:alertType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.alertType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Configure alert type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert Icon Visibility`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:showIcon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.showIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Configure visibiltiy alert icon`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      